import Vue from 'vue'
import axios from './axios'

export async function fetchConfig() {
    const defaultConfig = {};
    Vue.prototype.$config = defaultConfig;

    try {
        const response = await axios.get('/re_info/frontend_settings', {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            params: {
                t: Date.now(),
            }
        });

        Object.assign(defaultConfig, response.data);
    } catch (e) {
        // Try again after 15s
        setTimeout(window.location.reload, 15000)
        throw e;
    }

    return defaultConfig
}